<template>
  <div class="bg-black-0 rounded p-3 shadow-sm">
    <h2 v-if="!isEdit" class="mb-0 mb-sm-24">Tambah Akun Staff</h2>
    <h2 v-else class="mb-0 mb-sm-24">Edit Akun Staff</h2>

    <b-form @submit.prevent="onSubmit" class="mt-16 mt-sm-32 mb-8">
      <b-form-group
        v-for="(field, key) in formFields"
        :key="key"
        :label="field.label"
        :label-for="field.id"
        class="mb-16"
        v-if="!isEdit || (isEdit && !['password', 'c_password'].includes(key))"
      >
        <template v-if="field.type === 'select'">
          <v-select
            :id="field.id"
            v-model="form[key]"
            :options="field.options"
            :state="!v$.form[key].$error ? null : false"
            :placeholder="field.placeholder"
            @input="field.handleInput"
          />
        </template>
        <template v-else>
          <div :class="{ 'input-container': field.type === 'password' }">
            <b-form-input
              :id="field.id"
              v-model="form[key]"
              :state="!v$.form[key].$error ? null : false"
              :type="
                field.type === 'password'
                  ? showPassword
                    ? 'text'
                    : 'password'
                  : field.type
              "
              :placeholder="field.placeholder"
            />
            <span
              v-if="field.type === 'password'"
              class="eye-icon"
              @click="togglePasswordVisibility"
            >
              <b-icon :icon="showPassword ? 'eye-slash' : 'eye'" class="eye" />
            </span>
          </div>
        </template>
        <b-form-invalid-feedback :state="!v$.form[key].$error">
          {{ v$.form[key].$error ? errors[key] : "" }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-button
        type="submit"
        variant="danger"
        class="w-100 mt-5"
        :disabled="isLoading"
      >
        <b-spinner v-if="isLoading" small label="Small Spinner" />
        <span v-else>Buat Akun</span>
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { register } from "../../../../../api/auth/authAPI";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BSpinner,
  BToast,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
import manageUserAPI from "../../../../../api/manageuser/manageUserAPI";
import manageStaffAPI from "../../../../../api/manageStaff/manageStaffAPI";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,
    BSpinner,
    BToast,
  },
  data() {
    return {
      isEdit: false,
      form: {
        name: "",
        nik: "",
        email: "",
        password: "",
        c_password: "",
        role: null,
      },
      formFields: {
        name: {
          label: "Nama :",
          id: "registerName",
          type: "text",
          placeholder: "Nama",
        },
        nik: {
          label: "NIK :",
          id: "registerNIK",
          type: "text",
          placeholder: "NIK",
        },
        email: {
          label: "E-mail :",
          id: "registerEmail",
          type: "email",
          placeholder: "Email",
        },
        password: {
          label: "Kata Sandi",
          id: "registerPassword",
          type: "password",
          placeholder: "Kata Sandi",
        },
        c_password: {
          label: "Konfirmasi Kata Sandi",
          id: "registerCPassword",
          type: "password",
          placeholder: "Konfirmasi Kata Sandi",
        },
        role: {
          label: "Pilih Role Staff",
          id: "registerRole",
          type: "select",
          placeholder: "Pilih Role Staff",
          options: [
            { label: "Dokter", value: "DOCTOR" },
            { label: "Aftap", value: "AFTAP" },
            { label: "HB", value: "HB" },
            { label: "Registrasi", value: "REGISTER" },
            { label: "Admin", value: "ADMIN" },
            { label: "Komponen", value: "KOMPONEN" },
            { label: "IMLTD", value: "IMLTD" },
            { label: "Prolis", value: "PROLIS" },
            { label: "KGD", value: "KGD" },
          ],
          handleInput: (role) => (this.form.role = role.value),
        },
      },
      errors: {
        name: "Nama harus diisi",
        nik: "NIK harus diisi",
        email: "Email harus valid",
        password: "Kata sandi harus minimal 6 karakter",
        c_password: "Kata sandi tidak sesuai",
        role: "Role harus diisi",
      },
      showPassword: false,
      isLoading: false,
    };
  },
  validations() {
    const baseValidations = {
      name: { required },
      nik: { required },
      email: { required, email },
      role: { required },
    };

    if (!this.isEdit) {
      baseValidations.password = { required, minLength: minLength(6) };
      baseValidations.c_password = {
        required,
        sameAsPassword: sameAs(this.form.password),
      };
    }

    return {
      form: baseValidations,
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  mounted() {
    if (this.$route.params.id) {
      this.isEdit = true;
      this.fetchData();
    }
  },
  methods: {
    async fetchData() {
      try {
        const { data: response } = await manageUserAPI.get(
          this.$route.params.id
        );
        this.form.name = response.data.name;
        this.form.nik = response.data.nik;
        this.form.email = response.data.email;
        this.form.role = response.data.role;
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmit() {
      this.v$.form.$touch();
      if (this.v$.form.$invalid) {
        this.$bvToast.toast("Mohon lengkapi form", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return;
      }
      this.isLoading = true;
      try {
        if (this.isEdit) {
          const updateData = { ...this.form };
          updateData.id = this.$route.params.id;
          delete updateData.password;
          delete updateData.c_password;
          await manageStaffAPI.update(updateData);
        } else {
          await register(this.form);
        }
        this.$bvToast.toast("Data berhasil ditambahkan", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        setTimeout(() => {
          this.$router.push({ name: "pengelolaan-staff" });
        }, 500);
      } catch (error) {
        const errorMessages = error.response.data.message;
        errorMessages.forEach((msg) => {
          Object.keys(this.form).forEach((key) => {
            if (msg.toLowerCase().includes(key)) {
              this.errors[key] = msg;
            }
          });
        });
        this.$bvToast.toast(
          "Data gagal ditambahkan: " + error.response.data.message,
          {
            title: "Error",
            variant: "danger",
            solid: true,
          }
        );
      } finally {
        this.isLoading = false;
      }
    },
    handleError(error) {
      if (error.response && error.response.data.code === 400) {
        const errorMessages = error.response.data.message.split("\\n");
        errorMessages.forEach((msg) => {
          Object.keys(this.form).forEach((key) => {
            if (msg.toLowerCase().includes(key)) {
              this.errors[key] = msg;
            }
          });
        });
      } else {
        console.error(error);
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped>
.input-container {
  position: relative;
}

.input-container input[type="password"],
.input-container input[type="text"] {
  width: 100%;
  padding: 12px 36px 12px 12px;
  box-sizing: border-box;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
