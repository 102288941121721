import API from "../services/services";

class AktivasiPendonorService {
  getAll(params) {
    return API.get("manage_user/list_staff", { params });
  }
  update(payload) {
    return API.post("manage_user/list_staff/update", payload);
  }
}

export default new AktivasiPendonorService();
