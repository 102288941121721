import api from '../api'

const URLS = {
  login: 'auth/login',
  register: 'auth/register',
  forgot: 'forgot-password',
  reset: 'reset-password',
}

export const register = ({
  name, email, password, c_password, role, nik,
}) => api.post(URLS.register, {
  name,
  email,
  password,
  c_password,
  role,
  nik,
}, {
  baseURL: process.env.VUE_APP_VARIABLES,
})

export const login = ({ login_id, password }) => api.post(URLS.login, {
  login_id,
  password,
}, {
  baseURL: process.env.VUE_APP_VARIABLES,
})

export const forgot = ({ email }) => api.post(URLS.forgot, {
  email,
}, {
  baseURL: process.env.VUE_APP_BASE_URL1,
})

export const reset = ({ password, password_confirmation, token }) => api.post(URLS.reset, {
  password,
  password_confirmation,
  token,
}, {
  baseURL: process.env.VUE_APP_BASE_URL1,
})
